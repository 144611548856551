import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Cookies from 'universal-cookie';

function App() {

    const cookies = new Cookies();


    function handleButtonClick(version) {
        if(version)cookies.set('accept', true, {  expires: new Date(Date.now()+333332592000), path: '/', 'sameSite': 'none', 'secure': 'true' });
        if(!version)cookies.set('dismiss', true, { path: '/',  'sameSite': 'none', 'secure': 'true' });
        window.location.reload();
    }


    if(cookies.get('dismiss') || cookies.get('accept')) {

    }
    else if(!cookies.get('accept')){
        return (<div>
            <div className="overlay" style={{display:"block"}} id="overlay"></div>
            <div className="cookie-banner" id="cookieBanner">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-9">Wir nutzen Cookies und u.a. Google Analytics auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Sie können unsere Webseite grundsätzlich auch ohne das Setzen von Cookies besuchen. Hiervon ausgenommen sind die technisch notwendigen Cookies. Durch Klicken auf "akzeptieren" erklären Sie sich einverstanden, dass wir die vorgenannten Cookies zu Marketing- und zu Analyse-Zwecken setzen.</div>
                        <div className="col-3">
                            <div className="row">
                                <button className="col-12 btn btn-success" onClick={(e) =>handleButtonClick(true)}>akzeptieren</button>
                                <button  className="col-12 mt-2 btn btn-outline-secondary" onClick={(e) =>handleButtonClick(false)}>ablehnen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }




}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

